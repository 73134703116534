export default {
    global: {
        outer: "w-full mb-3",
        inner: "relative ",
        label: "block text-base font-medium mb-1 text-gray-900",
        input: "block w-full px-4 py-2 bg-white border border-gray-border rounded focus:outline-none disabled:bg-gray-50 placeholder-gray-light focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500 min-h-10 -my-[1px] disabled:bg-gray-medium  appearance-none",
        help: "text-xs text-black mt-2",
        messages: "list-none p-0 mt-3 mb-3 font-semi text-xs text-red-500",
        message: "mb-1 flex items-center gap-2 leading-tight",
        prefixIcon:
            "absolute top-1/2 -translate-y-1/2 w-6 left-3 pointer-events-none",
        suffixIcon:
            "absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none",
    },
    search: {
        input: 'pl-10 appearance-none',
    },
    button: {
        input: '$reset relative w-full px-4 py-2 text-white transition-colors duration-300 border border-transparent rounded bg-green-dark hover:bg-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:pink min-h-10 flex-center focus:text-white',
        wrapper: 'mt-4',
    },
    checkbox: {
        input: "$reset block w-4 h-4 mr-4 rounded focus:outline-none disabled:bg-gray-100",
        wrapper: "flex items-center py-2",
        label: "$reset block leading-tight mb-0 select-none cursor-pointer",
        decorator: "hidden",
        legend: "block mb-3 leading-none text-blue-500 text-base",
    },
    select: {
        outer: 'min-w-20',
        input: 'appearance-none',
        selectIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
    },
    textarea: {
        input: 'h-43',
    },
    radio: {
        legend: "block mb-2 leading-tight font-medium",
        wrapper: "flex items-center gap-3",
        label: "$reset cursor-pointer border border-[#B0B0B0] p-4",
        input: "$reset focus:ring-primary h-4 w-4 text-primary rounded-full bg-white border checked:bg-black p-[2px] bg-clip-content",

        options: "flex flex-col gap-2",
    },
};
