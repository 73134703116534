
import { flare } from '@flareapp/flare-client';

flare.beforeSubmit = (report) => {
    // Don't send reports from bots
    if (report.context.request.useragent.toLocaleLowerCase().includes('adsbot')) {
        return false;
    }

    return report;
};

export { flare };

